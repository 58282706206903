import { DataboxPageNew, HomePageNew, DataboxPageOld, IdentityPageNew, IdentityPageOld } from '../../pages';

export const routes = [
  {
    id: 1, 
    lang: 'cs', 
    route: 'identita-obcana', 
    component: IdentityPageNew
  },
  {
    id: 2, 
    lang: 'en', 
    route: 'identita-obcana',
    component: IdentityPageNew
  },
  {
    id: 3, 
    lang: 'ru', 
    route: 'identita-obcana', 
    component: IdentityPageOld
  },
  {
    id: 4, 
    lang: 'ua', 
    route: 'identita-obcana', 
    component: IdentityPageOld
  },
  {
    id: 5, 
    lang: 'cs', 
    route: 'datova-schranka', 
    component: DataboxPageNew
  },
  {
    id: 6, 
    lang: 'en', 
    route: 'datova-schranka', 
    component: DataboxPageNew
  },
  {
    id: 7, 
    lang: 'ru', 
    route: 'datova-schranka', 
    component: DataboxPageOld
  },
  {
    id: 8, 
    lang: 'ua', 
    route: 'datova-schranka', 
    component: DataboxPageOld
  },
  {
    id: 9, 
    lang: 'cs', 
    route: 'uvod', 
    component: HomePageNew
  },
  {
    id: 10, 
    lang: 'en', 
    route: 'uvod', 
    component: HomePageNew
  },
  {
    id: 11, 
    lang: 'cs', 
    route: '/', 
    component: DataboxPageNew
  },
  {
    id: 12, 
    lang: 'en', 
    route: 'en', 
    component: DataboxPageNew
  },
  {
    id: 13, 
    lang: 'ua', 
    route: 'ua', 
    component: DataboxPageNew
  },
  {
    id: 14,
    lang: 'ru', 
    route: 'ru', 
    component: DataboxPageNew
  },
];

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import {
  Container,
  Perex,
} from '../../gov-components';

import bell from '../../resources/icons/bell.svg';
import coins from '../../resources/icons/coins.svg';
import clock from '../../resources/icons/clock.svg';

export const TabInfoText = () => {
  const { t } = useTranslation();
  
    return (
      <>
        <section id={ 'info-text-id' }>
          <Container className={ 'gov-container--800px u-bg-color--white gov-tabs--infotile pb-56 pt-0' }>
            <Perex className={ 'gov-tabs__subtitle-text u-mt--24' }>{t('infotilePage.tabItem.textTwoPartOne')}
              <b>{t('infotilePage.tabItem.textTwoPartTwo') }</b>
              <b>{t('infotilePage.tabItem.textTwoPartThree') }</b> {t('infotilePage.tabItem.textTwoPartFour')}</Perex>
            <div className={ 'gov-tabs__icons-holder' }>
              <div className={ 'gov-tabs__icons-holder--item' }>
                <ReactSVG src={ clock } alt={t('infotilePage.tabItem.imageAltThree')}
                          className={ 'gov-tabs__icons-holder--item-icon' }/>
                <Perex className={ 'gov-tabs__icons-holder--item-text' }>{t('infotilePage.tabItem.imageTextFive')}</Perex>
              </div>
              <div className={ 'gov-tabs__icons-holder--item' }>
                <ReactSVG src={ coins } alt={t('infotilePage.tabItem.imageAltTwo')}
                          className={ 'gov-tabs__icons-holder--item-icon' }/>
                <Perex className={ 'gov-tabs__icons-holder--item-text' }>{t('infotilePage.tabItem.imageTextFour')}</Perex>
              </div>
              <div className={ 'gov-tabs__icons-holder--item' }>
                <ReactSVG src={ bell } alt={t('infotilePage.tabItem.imageAltSix')}
                          className={ 'gov-tabs__icons-holder--item-icon' }/>
                <Perex className={ 'gov-tabs__icons-holder--item-text' }>{t('infotilePage.tabItem.imageTextSix')}</Perex>
              </div>
            </div>
          </Container>
        </section>
      </>
    )
  }

import { useEffect } from 'react';
import { withRouter } from 'react-router';

const ScrollToTop = ((props: any) => {
  useEffect(() => {
    const dispose = props.history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      dispose();
    }
  }, []);

    return null;
});

export default withRouter(ScrollToTop);

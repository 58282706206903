import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

const ScrollToElement = (routeProps: RouteComponentProps) => {
  useEffect(() => {
    let hash = routeProps?.location?.hash;
      if (!hash) {
        return;
      }

    hash = hash.replace('#', '');
    const el = document.getElementById(hash);
      if (!el) {
        return;
      }

      setTimeout(() => {
        window.scrollTo({
          behavior: "smooth",
          top: el.offsetTop
        });
      }, 500);

  }, [routeProps?.location?.hash]);

  return null;
}

export default withRouter(ScrollToElement);

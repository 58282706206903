import { useTranslation } from 'react-i18next';
import * as Constants from './constants';

export const withLocale = (relativeUrl: string) => {
  const { i18n } = useTranslation();
  if (!relativeUrl.startsWith('/')) {
    relativeUrl = `/${relativeUrl}`;
  }

  const locale = i18n.language;
  if (!Constants.SUPPORTED_LOCALES.some(i => i === locale)) {
    return relativeUrl;
  }
  if (locale === Constants.DEFAULT_LOCALE) {
    return relativeUrl;
  }
  // TEMP: Remove once full support is added for UA and RU
  if (locale === 'ua' || locale === 'ru') {
    if (relativeUrl === Constants.ROUTE_PATHS.intro) {
      return relativeUrl;
    }
  }
  return `/${i18n.language}${relativeUrl}`;
}

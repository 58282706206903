export let DataboxNewFAQ  = (t: any) => JSON.stringify({
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: t("databoxAccordionsNew.titleOne"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>{${t('databoxAccordionsNew.textOnePartOne')} <a href={'https://info.mojedatovaschranka.cz/info/'} target='_blank' className={'stepper-wrapper__text--link'}>${t('databoxAccordionsNew.textLinkOne')}</a> ${t('databoxAccordionsNew.textOnePartTwo')} <b>${t('databoxAccordionsNew.textOnePartThree')}</b> ${t('databoxAccordionsNew.textOnePartFour')} <b>${t('databoxAccordionsNew.textOnePartFive')}</b> ${t('databoxAccordionsNew.textOnePartSix')} <b>${t('databoxAccordionsNew.textOnePartSeven')}</b> ${t('databoxAccordionsNew.textOnePartEight')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNew.titleThree"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNew.textFourPartOne')} <b>${t('databoxAccordionsNew.textFourPartTwo')}</b> ${t('databoxAccordionsNew.textFourPartThree')} <b>${t('databoxAccordionsNew.textFourPartFour')}</b></p>\n<p>${t('databoxAccordionsNew.textFivePartOne')} ${t('databoxAccordionsNew.textSixLink')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNew.titleThreeNew"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNew.textThreeNewPartOne')} ${t('databoxAccordionsNew.textThreeNewPartTwoLink')} ${t('databoxAccordionsNew.textThreeNewPartThree')}</p>\n<p>${t('databoxAccordionsNew.textThreeNewPartFour')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNew.titleTwo"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNew.textTwoPartOne')} <b>${t('databoxAccordionsNew.textTwoPartTwo')}</b> ${t('databoxAccordionsNew.textTwoPartThree')}</p>\n<p><b>${t('databoxAccordionsNew.textThreePartOne')}</b> ${t('databoxAccordionsNew.textThreePartTwo')} <b>${t('databoxAccordionsNew.textThreePartThree')}</b> ${t('databoxAccordionsNew.textThreePartFour')} <b>${t('databoxAccordionsNew.textThreePartFive')}</b> ${t('databoxAccordionsNew.textThreePartSix')}</p>\n<p><a href={'https://info.mojedatovaschranka.cz/info/cs/82.html'} target='_blank' className={'stepper-wrapper__text--link'}>${t('databoxAccordionsNew.textFourLink')}</a></p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNew.titleFour"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNew.textSevenPartOne')} <b>${t('databoxAccordionsNew.textSevenPartTwo')}</b> ${t('databoxAccordionsNew.textSevenPartThree')}</p>\n<p>${t('databoxAccordionsNew.textEightPartOne')}</p>\n<ul className={'unordered-list no-margin-bottom'}><li>${t('databoxAccordionsNew.textNinePartOne')}</li><li>${t('databoxAccordionsNew.textNinePartTwo')}</li><li>${t('databoxAccordionsNew.textNinePartThree')}</li></ul>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNew.titleSix"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNew.textTwelvePartOne')}</p>\n<p>${t('databoxAccordionsNew.textThirteenPartOne')} <b>${t('databoxAccordionsNew.textThirteenPartTwo')}</b> ${t('databoxAccordionsNew.textThirteenPartThree')}</p>\n<p>${t('databoxAccordionsNew.textFourteenPartOne')} <b>${t('databoxAccordionsNew.textFourteenPartTwo')}</b> ${t('databoxAccordionsNew.textFourteenPartThree')}</p>\n<p>${t('databoxAccordionsNew.textFifteenPartOne')}</p>\n<p><a href={'https://info.mojedatovaschranka.cz/info/cs/65.html'} target='_blank' className={'stepper-wrapper__text--link'}>${t('databoxAccordionsNew.textSixteenPartOneLink')}</a></p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNew.titleSeven"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNew.textSeventeenPartOne')} <b>${t('databoxAccordionsNew.textSeventeenPartTwo')}</b> ${t('databoxAccordionsNew.textSeventeenPartThree')} <b>${t('databoxAccordionsNew.textSeventeenPartFour')}</b> ${t('databoxAccordionsNew.textSeventeenPartFive')}</p>\n<p>${t('databoxAccordionsNew.textEighteenPartOne')} <a href={'https://info.mojedatovaschranka.cz/info/cs/1025.html'} target='_blank' className={'stepper-wrapper__text--link'}>${t('databoxAccordionsNew.textEighteenPartTwoLink')}</a></p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNew.titleEightNew"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNew.textEightNewPartOne')} <b>${t('databoxAccordionsNew.textEightNewPartTwo')}</b> ${t('databoxAccordionsNew.textEightNewPartThree')}</p>\n<p>${t('databoxAccordionsNew.textEightNewPartFour')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNew.titleFive"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNew.textTenPartOne')} <a href={'https://obcan.portal.gov.cz/prihlaseni'} target='_blank' className={'stepper-wrapper__text--link'}>${t('databoxAccordionsNew.textTenPartTwoLink')}</a> ${t('databoxAccordionsNew.textTenPartThree')} <b>${t('databoxAccordionsNew.textTenPartFour')}</b> ${t('databoxAccordionsNew.textTenPartFive')} <a href={'https://info.mojedatovaschranka.cz/info/'} target='_blank' className={'stepper-wrapper__text--link'}>${t('databoxAccordionsNew.textTenPartSixLink')}</a>.</p>\n<p>${t('databoxAccordionsNew.textElevenPartOne')} <a href={'https://info.mojedatovaschranka.cz/info/'} target='_blank' className={'stepper-wrapper__text--link'}>${t('databoxAccordionsNew.textElevenPartTwoLink')}</a> ${t('databoxAccordionsNew.textElevenPartThree')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNew.titleNineNew"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNew.textNineNewPartOne')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNewSetup.titleOne"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNewSetup.textOnePartOne')}</p>\n<p>${t('databoxAccordionsNewSetup.textOnePartTwo')}</p>\n<p>${t('databoxAccordionsNewSetup.textOnePartThree')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNewSetup.titleTwo"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNewSetup.textTwoPartOne')}</p>\n<p>${t('databoxAccordionsNewSetup.textTwoPartTwo')} ${t('databoxAccordionsNewSetup.textTwoPartThreeLink')}</p>\n<p>${t('databoxAccordionsNewSetup.textTwoPartFour')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNewSetup.titleFour"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNewSetup.textFourPartOne')} ${t('databoxAccordionsNewSetup.textFourPartTwoLink')} ${t('databoxAccordionsNewSetup.textFourPartThree')}</p>\n<p>${t('databoxAccordionsNewSetup.textFourPartFour')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNew.titleNine"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNew.textTwentyTwoPartOne')} <b>${t('databoxAccordionsNew.textTwentyTwoPartTwo')}</b> ${t('databoxAccordionsNew.textTwentyTwoPartThree')} <a href={'https://info.mojedatovaschranka.cz/info/'} target='_blank' className={'stepper-wrapper__text--link'}>${t('databoxAccordionsNew.textTwentyTwoPartThreeLink')}</a> ${t("databoxAccordionsNew.textTwentyTwoPartThreeNew")} <b>${t('databoxAccordionsNew.textTwentyTwoPartFour')}</b> ${t('databoxAccordionsNew.textTwentyTwoPartFive')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNew.titleEight"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNew.textEightPartOneNew')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNewSetup.titleThree"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNewSetup.textThree')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNewSetup.titleSix"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNewSetup.textSixPartOne')}</p>\n<p>${t('databoxAccordionsNewSetup.textSixPartTwo')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNewSetup.titleSeven"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNewSetup.textSevenPartOne')}</p>\n<p>${t('databoxAccordionsNewSetup.textSevenPartTwo')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNewSetup.titleEight"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p><b>${t('databoxAccordionsNewSetup.textEightPartOne')}</b> ${t('databoxAccordionsNewSetup.textEightPartTwo')} <a href={'https://www.datoveschranky.info/chci-datovku/typy-datovych-schranek'} target='_blank' className={'stepper-wrapper__text--link'}>${t('databoxAccordionsNewSetup.textEightPartThreeLink')}</a></p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNewSetup.titleNine"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p><b>${t('databoxAccordionsNewSetup.textNinePartOne')}</b> ${t('databoxAccordionsNewSetup.textNinePartTwo')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNewSetup.titleTen"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p><b>${t('databoxAccordionsNewSetup.textTenPartOne')}</b> ${t('databoxAccordionsNewSetup.textTenPartTwo')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNewCommunication.titleOne"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('databoxAccordionsNewCommunication.textOnePartOne')}</p>\n<p>${t('databoxAccordionsNewCommunication.textOnePartTwo')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNewCommunication.titleTwo"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p><b>${t('databoxAccordionsNewCommunication.textTwoPartOne')}</b> ${t('databoxAccordionsNewCommunication.textTwoPartTwo')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNewCommunication.titleThree"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p><b>${t('databoxAccordionsNewCommunication.textThreePartOne')}</b> ${t('databoxAccordionsNewCommunication.textThreePartTwo')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("databoxAccordionsNewCommunication.titleFour"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p><b>${t('databoxAccordionsNewCommunication.textFourPartOne')}</b> ${t('databoxAccordionsNewCommunication.textFourPartTwo')} <b>${t('databoxAccordionsNewCommunication.textFourPartThree')}</b> ${t('databoxAccordionsNewCommunication.textFourPartFour')}</p>\n<p>${t('databoxAccordionsNewCommunication.textFourPartFive')}</p>`,
      },
    },
  ],
});

export let IdentityNewFAQ = (t: any) => JSON.stringify({
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: t("identityAccordionsNew.titleOne"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p><b>${t('identityAccordionsNew.textOnePartOne')}</b> ${t('identityAccordionsNew.textOnePartTwo')} <a href={'https://obcan.portal.gov.cz/prihlaseni'} target='_blank' className={'stepper-wrapper__text--link'}>${t('identityAccordionsNew.textLinkOne')}</a> ${t('identityAccordionsNew.textOnePartThree')}</p>\n<p>${t('identityAccordionsNew.textOnePartFour')} <a href={'https://obcan.portal.gov.cz/prihlaseni'} target='_blank' className={'stepper-wrapper__text--link'}>${t('identityAccordionsNew.textOnePartFiveLink')}</a> ${t('identityAccordionsNew.textOnePartSix')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("identityAccordionsNew.titleTwo"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p><b>${t('identityAccordionsNew.textTwoPartOne')}</b> ${t('identityAccordionsNew.textTwoPartTwo')} <b>${t('identityAccordionsNew.textTwoPartThree')}</b> ${t('identityAccordionsNew.textTwoPartFour')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("identityAccordionsNew.titleThree"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p><a href={'https://www.identitaobcana.cz/Home'} target='_blank' className={'stepper-wrapper__text--link'}>${t('identityAccordionsNew.textThreePartOneLink')}</a></p>\n<p>${t('identityAccordionsNew.textFourPartOne')} <b>${t('identityAccordionsNew.textFourPartTwo')}</b></p>`,
      },
    },
    {
      "@type": "Question",
      name: t("identityAccordionsNew.titleFour"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('identityAccordionsNew.textFivePartOne')} <a href={'https://info.identitaobcana.cz/Podpora.aspx'} target='_blank' className={'stepper-wrapper__text--link'}>${t('identityAccordionsNew.textFivePartTwoLink')}</a> ${t('identityAccordionsNew.textFivePartThree')}</p>\n<p>${t('identityAccordionsNew.textSixPartOne')} <a href={'https://info.identitaobcana.cz/Dostupnost/'} target='_blank' className={'stepper-wrapper__text--link'}>${t('identityAccordionsNew.textSixPartTwoLink')}</a></p>`,
      },
    },
    {
      "@type": "Question",
      name: t("identityAccordionsNew.titleFive"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p><b>${t('identityAccordionsNew.textSevenPartOne')}</b> ${t('identityAccordionsNew.textSevenPartTwo')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("identityAccordionsNew.titleSix"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('identityAccordionsNew.textEightPartOne')} <b>${t('identityAccordionsNew.textEightPartTwo')}</b> ${t('identityAccordionsNew.textEightPartThree')}</p>\n<p><a href={'https://info.identitaobcana.cz/foreigners/'} target='_blank' className={'stepper-wrapper__text--link'}>${t('identityAccordionsNew.textNinePartOneLink')}</a> ${t('identityAccordionsNew.textNinePartTwo')} <a href={'https://info.identitaobcana.cz/idp/'} target='_blank' className={'stepper-wrapper__text--link'}>${t('identityAccordionsNew.textNinePartThreeLink')}</a></p>`,
      },
    },
    {
      "@type": "Question",
      name: t("identityAccordionsNew.titleSeven"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('identityAccordionsNew.textTenPartOne')} <b>${t('identityAccordionsNew.textTenPartTwo')}</b> ${t('identityAccordionsNew.textTenPartThree')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("identityAccordionsNew.titleEight"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('identityAccordionsNew.textElevenPartOne')} <b>${t('identityAccordionsNew.textElevenPartTwo')}</b> ${t('identityAccordionsNew.textElevenPartThree')} <a href={'https://info.identitaobcana.cz/mep/'} target='_blank' className={'stepper-wrapper__text--link'}>${t('identityAccordionsNew.textElevenPartThreeLink')}</a> ${t('identityAccordionsNew.textElevenPartFour')} <a href={'https://www.identitaobcana.cz/ProfileRegistration'} target='_blank' className={'stepper-wrapper__text--link'}>${t('identityAccordionsNew.textElevenPartFiveLink')}</a></p>`,
      },
    },
  ],
});

export let DataboxOldFAQ = (t: any) => JSON.stringify({
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: t("curiosity.questions.accordion.seventh.heading"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('curiosity.questions.accordion.seventh.text.first')} ${t('curiosity.questions.accordion.seventh.text.second')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("curiosity.questions.accordion.first.heading"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('curiosity.questions.accordion.first.text.first')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("curiosity.questions.accordion.second.heading"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('curiosity.questions.accordion.second.text.first')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("curiosity.questions.accordion.third.heading"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('curiosity.questions.accordion.third.text.first')}</p>\n<p>${t('curiosity.questions.accordion.third.text.second')} <a href={'https://www.ceskaposta.cz/sluzby/egovernment/doplnkove-sluzby-k-datovym-schrankam/postovni-datova-zprava'} target='_blank' className={'plain-list__link'}>${t('curiosity.questions.accordion.third.text.third')}</a>.</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("curiosity.questions.accordion.sixthAsThird.heading"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('curiosity.questions.accordion.sixthAsThird.textFirst')} <a href={'https://play.google.com/store/apps/details?id=cz.nic.mobiledatovka'} target='_blank' className={'plain-list__link'}>${t('curiosity.questions.accordion.sixthAsThird.textSecond')}</a> ${t('curiosity.questions.accordion.sixthAsThird.textThird')} <a href={'https://apps.apple.com/cz/app/datovka/id1185445299?l=cs'} target='_blank' className={'plain-list__link'}>${t('curiosity.questions.accordion.sixthAsThird.textFourth')}</a>.</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("curiosity.questions.accordion.fourth.heading"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('curiosity.questions.accordion.fourth.text.first')}</p>\n<p>${t('curiosity.questions.accordion.fourth.text.second')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("curiosity.questions.accordion.fifth.heading"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('curiosity.questions.accordion.fifth.text.first')}</p>\n<p className={'no-margin-bottom'}>${t('curiosity.questions.accordion.fifth.text.second')}</p>\n<ul className={'unordered-list'}><li>${t('curiosity.questions.accordion.fifth.text.third')}</li><li>${t('curiosity.questions.accordion.fifth.text.fourth')}</li><li>${t('curiosity.questions.accordion.fifth.text.fifth')}</li></ul>`,
      },
    },
  ],
});

export let IdentityOldFAQ = (t: any) => JSON.stringify({
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: t("mainPage.sectionFour.itemOne.title"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('mainPage.sectionFour.itemOne.textOne')}</p>\n<p><a href=${t('mainPage.sectionFour.itemOne.textTwoLinkHref')} target='_blank' className={'plain-list__link'}>${t('mainPage.sectionFour.itemOne.textTwoLink')}</a>.</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("mainPage.sectionFour.itemTwo.title"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('mainPage.sectionFour.itemTwo.textOne')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("mainPage.sectionFour.itemThree.title"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('mainPage.sectionFour.itemThree.textOne')}</p>`,
      },
    },
    {
      "@type": "Question",
      name: t("mainPage.sectionFour.itemFour.title"),
      acceptedAnswer: {
        "@type": "Answer",
        text: `<p>${t('mainPage.sectionFour.itemFour.textOnePartOne')} <a href=${t('mainPage.sectionFour.itemFour.textOnePartTwoLinkHref')} target='_blank' className={'plain-list__link'}>${t('mainPage.sectionFour.itemFour.textOnePartTwoLink')}</a> ${t('mainPage.sectionFour.itemFour.textOnePartThree')}</p>\n<p>${t('mainPage.sectionFour.itemFour.textTwoPartOne')} <a href=${t('mainPage.sectionFour.itemFour.textTwoPartTwoLinkHref')} target='_blank' className={'plain-list__link'}>${t('mainPage.sectionFour.itemFour.textTwoPartTwoLink')}</a> ${t('mainPage.sectionFour.itemFour.textTwoPartThree')}</p>`,
      },
    },
  ],
});

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../../gov-components';

export const InfoNumbersSection = () => {
  const { t } = useTranslation();

  return (
    <Container className={ 'gov-container--900px container-six' }>
      <div className={ 'container-six__sixth-section' }>
        <div className={ 'container-six__sixth-section--numbers' }>
          <div className={ 'container-six__sixth-section--big-number' }>916 {t('global.millionShortcut')}</div>
          <div className={ 'container-six__sixth-section--small-text' }>{t('statistics.dataMessages')}</div>
        </div>
        <div className={ 'container-six__sixth-section--numbers' }>
          <div className={ 'container-six__sixth-section--big-number' }>1,4 {t('global.millionShortcut')}</div>
          <div className={ 'container-six__sixth-section--small-text' }>{t('statistics.dataBoxes')}</div>
        </div>
        <div className={ 'container-six__sixth-section--numbers' }>
          <div className={ 'container-six__sixth-section--big-number' }>967 {t('global.thousandShortcut')}</div>
          <div className={ 'container-six__sixth-section--small-text' }>{t('statistics.savedHours')}</div>
        </div>
        <div className={ 'container-six__sixth-section--numbers' }>
          <div className={ 'container-six__sixth-section--big-number' }>28 {t('global.billionShortcut')}</div>
          <div className={ 'container-six__sixth-section--small-text' }>{t('statistics.savedMoney')}</div>
        </div>
      </div>
    </Container>
  )
}

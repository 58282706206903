import * as React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import {
  Container,
  Headline,
  Link,
  Perex,
  Tabs,
  TabsItem
} from '../../gov-components';

import infoOutline from '../../resources/icons/info-outline.svg';
import dataBox from '../../resources/icons/data-box.svg';
import lion from '../../resources/icons/lion.svg';
import coins from '../../resources/icons/coins.svg';
import clock from '../../resources/icons/clock.svg';
import timeBlue from '../../resources/icons/time-blue.svg';

import * as Constants from '../../util/constants';
import { withLocale } from '../../util/routing';

interface InfotileTabsProps {
  scrollToEnd?: boolean;
}

export const InfotileTabs = (props: InfotileTabsProps) => {
  const { t } = useTranslation();

  const tabsRef = useRef();
  
  React.useEffect(() => {
    const el: any = tabsRef?.current;
    if (props.scrollToEnd && el) {
      el.scrollLeft = el.scrollWidth;
    }
  }, [props.scrollToEnd, tabsRef?.current]);

  return (
    <>
      <section id={ 'hlavni-infodlazdice' }>
        <Container className={ 'gov-container--900px u-bg-color--white gov-infotile-tabs' }>
          <Tabs version={ 'infotile' } tabsRef={tabsRef}>
            <TabsItem
              icon={<ReactSVG src={ infoOutline } alt={t('infotilePage.tabItem.imageAltOne')} />}
              title={t('infotilePage.tabItem.titleOne')}
              text={t('infotilePage.tabItem.subtitleOne')}
              routePath={ withLocale(Constants.ROUTE_PATHS.intro) }
            >
              <Headline type={1} className={ 'gov-tabs__title-text' }>{t('infotilePage.tabItem.textOneHeading')}</Headline>
              <Perex className={ 'gov-tabs__subtitle-text' }>{t('infotilePage.tabItem.textOnePartOne')}&nbsp;
              <b>{t('infotilePage.tabItem.textOnePartTwo')}</b> {t('infotilePage.tabItem.textOnePartThree')}&nbsp;
              <b>{t('infotilePage.tabItem.textOnePartFour')}</b> {t('infotilePage.tabItem.textOnePartFive')}&nbsp;
              <b>{t('infotilePage.tabItem.textOnePartSix')}</b> {t('infotilePage.tabItem.textOnePartSeven')}&nbsp;
                <Link
                  title={t('infotilePage.tabItem.linkOneTitle')}
                  href={ withLocale(Constants.ROUTE_PATHS.identity) }
                  className={ 'gov-tabs__text-link' }
                >{t('infotilePage.tabItem.textOnePartEightLink')}
                </Link>&nbsp; {t('infotilePage.tabItem.textOnePartNine')}&nbsp;
                <Link
                  title={t('infotilePage.tabItem.linkTwoTitle')}
                  href={ withLocale(Constants.ROUTE_PATHS.databox) }
                  className={ 'gov-tabs__text-link' }
                >{t('infotilePage.tabItem.textOnePartTenLink')}
                </Link>.</Perex>
              <div className={ 'gov-tabs__icons-holder' }>
                <div className={ 'gov-tabs__icons-holder--item' }>
                  <ReactSVG src={ coins } alt={t('infotilePage.tabItem.imageAltTwo')} className={ 'gov-tabs__icons-holder--item-icon' } />
                  <Perex className={ 'gov-tabs__icons-holder--item-text' }>{t('infotilePage.tabItem.imageTextOne')}</Perex>
                </div>
                <div className={ 'gov-tabs__icons-holder--item' }>
                  <ReactSVG src={ clock } alt={t('infotilePage.tabItem.imageAltThree')} className={ 'gov-tabs__icons-holder--item-icon' } />
                  <Perex className={ 'gov-tabs__icons-holder--item-text' }>{t('infotilePage.tabItem.imageTextTwo')}</Perex>
                </div>
                <div className={ 'gov-tabs__icons-holder--item' }>
                  <ReactSVG src={ timeBlue } alt={t('infotilePage.tabItem.imageAltFour')} className={ 'gov-tabs__icons-holder--item-icon' } />
                  <Perex className={ 'gov-tabs__icons-holder--item-text' }>{t('infotilePage.tabItem.imageTextThree')}</Perex>
                </div>
              </div>
            </TabsItem>
            <TabsItem
              icon={<ReactSVG src={ dataBox } alt={t('infotilePage.tabItem.imageAltFive')} />}
              title={t('infotilePage.tabItem.titleTwo')}
              text={t('infotilePage.tabItem.subtitleTwo')}
              routePath={ withLocale(Constants.ROUTE_PATHS.databox) }
            >
            </TabsItem>
            <TabsItem
              icon={<ReactSVG src={ lion } alt={t('infotilePage.tabItem.imageAltSeven')} />}
              title={t('infotilePage.tabItem.titleThree')}
              text={t('infotilePage.tabItem.subtitleThree')}
              routePath={ withLocale(Constants.ROUTE_PATHS.identity) }
            >
              <Headline type={2} className={ 'gov-tabs__title-text heading2' }>{t('infotilePage.tabItem.textThreeHeading')}</Headline>
              <Perex className={ 'gov-tabs__subtitle-text' }>{t('infotilePage.tabItem.textThreePartOne')}&nbsp;
              <b>{t('infotilePage.tabItem.textThreePartTwo')}</b> {t('infotilePage.tabItem.textThreePartThree')}&nbsp;
              <b>{t('infotilePage.tabItem.textThreePartFour')}</b> {t('infotilePage.tabItem.textThreePartFive')}</Perex>
              <div className={ 'gov-tabs__icons-holder' }>
                <div className={ 'gov-tabs__icons-holder--item' }>
                  <ReactSVG src={ infoOutline } alt={t('infotilePage.tabItem.imageAltOne')} className={ 'gov-tabs__icons-holder--item-icon' } />
                  <Perex className={ 'gov-tabs__icons-holder--item-text' }>{t('infotilePage.tabItem.imageTextSeven')}</Perex>
                </div>
                <div className={ 'gov-tabs__icons-holder--item' }>
                  <ReactSVG src={ coins } alt={t('infotilePage.tabItem.imageAltTwo')} className={ 'gov-tabs__icons-holder--item-icon' }/>
                  <Perex className={ 'gov-tabs__icons-holder--item-text' }>{t('infotilePage.tabItem.imageTextEight')}</Perex>
                </div>
                <div className={ 'gov-tabs__icons-holder--item' }>
                  <ReactSVG src={ clock } alt={t('infotilePage.tabItem.imageAltThree')} className={ 'gov-tabs__icons-holder--item-icon' }/>
                  <Perex className={ 'gov-tabs__icons-holder--item-text' }>{t('infotilePage.tabItem.imageTextNine')}</Perex>
                </div>
              </div>
            </TabsItem>
          </Tabs>
        </Container>
      </section>
    </>
  )
}

export const LOADER_LENGTH = {
  inline: 3,
  block: 8,
};

export const TIMEPICKER_MAX_VALUE_12_HOUR_FORMAT = 12;

export const CALENDAR_LAST_MONTH_INDEX = 11;

export const ROUTE_HASH_IDS = {
  newDatabox:           'zalozeni-datova-schranka',
  newIdentity:          'zalozeni-identita-obcana',
};

export const ROUTE_PATHS = {
  intro:                      '/uvod',
  databox:                    '/datova-schranka',
  databoxSetUp:               `/datova-schranka#${ROUTE_HASH_IDS.newDatabox}`,
  identity:                   '/identita-obcana',
  identitySetUp:              `/identita-obcana#${ROUTE_HASH_IDS.newIdentity}`
};

export const LOCALES = {
  cs: 'cs',
  en: 'en',
  ua: 'ua',
  ru: 'ru'
}

export const SUPPORTED_LOCALES = [
  LOCALES.cs,
  LOCALES.en,
  LOCALES.ua,
  LOCALES.ru
];

export const DEFAULT_LOCALE = LOCALES.cs;

import * as React from 'react';
import {
  Grid,
  GridTile,
  Link,
  Paragraph,
  PreviewTile
} from '../../gov-components';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import erbGov from '../../resources/icons/erb.svg';
import injection from '../../resources/icons/injection.svg';
import externalLink from '../../resources/icons/external.svg';
import dataBox from '../../resources/icons/data-box.svg';
import socialSecurityAdministrationLogo from '../../resources/icons/cssz.svg';
import portalMyTaxes from '../../resources/icons/portal-my-taxes.svg';
import portalInsuranceCompanies from '../../resources/icons/portal-insurance-companies.svg';
import pillsPrescription from '../../resources/icons/pills-prescription.svg';
import portalForRegions from '../../resources/icons/portal-for-regions.svg';
import help from '../../resources/icons/help-icon.svg';

export const CzechAndEnglishIdentityModals = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid gridSize={3} className={ 'grid-czech-english' }>
        <GridTile modalTitle={t("mainPage.sectionOne.modalOne.title")}
                  modalButtonName={t("mainPage.sectionOne.modalOne.button")}
                  buttonLink={t("mainPage.sectionOne.modalOne.hrefLink")}
                  modalContent={
          <>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOne.modalOne.textOne")}</Paragraph>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOne.modalOne.textTwo")}</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ erbGov }
                            alt={t("mainPage.sectionOne.modalOne.altText")}
                            className={ 'erb-gov-blue' } />}
            headline={t("mainPage.sectionOne.modalOne.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("mainPage.sectionOne.modalTwo.title")}
                  modalButtonName={t("mainPage.sectionOne.modalTwo.button")}
                  buttonLink={t("mainPage.sectionOne.modalTwo.hrefLink")}
                  modalContent={
          <>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOne.modalTwo.textOne")}</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ injection }
                            alt={t("mainPage.sectionOne.modalTwo.altText")}
                            className={ 'injection-icon' } />}
            headline={t("mainPage.sectionOne.modalTwo.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("mainPage.sectionOne.modalThree.title")}
                  modalButtonName={t("mainPage.sectionOne.modalThree.button")}
                  buttonLink={t("mainPage.sectionOne.modalThree.hrefLink")}
                  modalContent={
          <>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOne.modalThree.textOne")}</Paragraph>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOne.modalThree.textTwoPartOne")}&nbsp;
              <Link
                title={t("mainPage.sectionOne.modalThree.textTwoLinkOneTitle")}
                href={t("mainPage.sectionOne.modalThree.textTwoLinkOneHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionOne.modalThree.textTwoLinkOne")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp;.</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ dataBox }
                            alt={t("mainPage.sectionOne.modalThree.altText")}
                            className={ 'data-box-icon' } />}
            headline={t("mainPage.sectionOne.modalThree.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("mainPage.sectionOne.modalFour.title")}
                  modalButtonName={t("mainPage.sectionOne.modalFour.button")}
                  buttonLink={t("mainPage.sectionOne.modalFour.hrefLink")}
                  modalContent={
          <>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOne.modalFour.textOne")}</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ socialSecurityAdministrationLogo }
                            alt={t("mainPage.sectionOne.modalFour.altText")}
                            className={ 'logo-social-security-administration' } />}
            headline={t("mainPage.sectionOne.modalFour.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("mainPage.sectionOne.modalFive.title")}
                  modalButtonName={t("mainPage.sectionOne.modalFive.button")}
                  buttonLink={t("mainPage.sectionOne.modalFive.hrefLink")}
                  modalContent={
          <>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOne.modalFive.textOne")}</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<span className="gov-complex-icon gov-complex-icon--map" />}
            headline={t("mainPage.sectionOne.modalFive.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("mainPage.sectionOne.modalSix.title")}
                  modalButtonName={t("mainPage.sectionOne.modalSix.button")}
                  buttonLink={t("mainPage.sectionOne.modalSix.hrefLink")}
                  modalContent={
          <>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOne.modalSix.textOne")}</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ portalMyTaxes }
                            alt={t("mainPage.sectionOne.modalSix.altText")}
                            className={ 'portal-my-taxes' } /> }
            headline={t("mainPage.sectionOne.modalSix.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("mainPage.sectionOne.modalSeven.title")}
                  noButton={true}
                  modalContent={
          <>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOne.modalSeven.textOne")}</Paragraph>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOne.modalSeven.textTwoPartOne")}&nbsp;
              <Link
                title={t("mainPage.sectionOne.modalSeven.textTwoLinkOneTitle")}
                href={t("mainPage.sectionOne.modalSeven.textTwoLinkOneHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionOne.modalSeven.textTwoLinkOne")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>.
            </Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ portalInsuranceCompanies }
                            alt={t("mainPage.sectionOne.modalSeven.altText")}
                            className={ 'insurance-company' } />}
            headline={t("mainPage.sectionOne.modalSeven.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("mainPage.sectionOne.modalEight.title")}
                  modalButtonName={t("mainPage.sectionOne.modalEight.button")}
                  buttonLink={t("mainPage.sectionOne.modalEight.hrefLink")}
                  modalContent={
          <>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOne.modalEight.textOne")}</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ pillsPrescription }
                            alt={t("mainPage.sectionOne.modalEight.altText")}
                            className={ 'pills-prescription' }/>}
            headline={t("mainPage.sectionOne.modalEight.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("mainPage.sectionOne.modalNine.title")}
                  noButton={true}
                  modalContent={
          <>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOne.modalNine.textOne")}</Paragraph>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOne.modalNine.textTwoPartOne")}&nbsp;
              <Link
                title={t("mainPage.sectionOne.modalNine.textTwoLinkOneTitle")}
                href={t("mainPage.sectionOne.modalNine.textTwoLinkOneHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionOne.modalNine.textTwoLinkOne")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp; {t("mainPage.sectionOne.modalNine.textTwoPartTwo")}&nbsp;
              <Link
                title={t("mainPage.sectionOne.modalNine.textTwoLinkTwoTitle")}
                href={t("mainPage.sectionOne.modalNine.textTwoLinkTwoHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionOne.modalNine.textTwoLinkTwo")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp;.
            </Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ portalForRegions }
                             alt={t("mainPage.sectionOne.modalNine.altText")}
                             className={ 'portal-for-regions' } />}
            headline={t("mainPage.sectionOne.modalNine.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
      </Grid>
    </>
  )
}

export const UkraineAndRussianIdentityModals = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid gridSize={3} className={ 'grid-ukrainian-russian' }>
        <GridTile modalTitle={t("mainPage.sectionOneUA.modalOne.title")}
                  modalButtonName={t("mainPage.sectionOneUA.modalOne.button")}
                  buttonLink={t("mainPage.sectionOneUA.modalOne.hrefLink")}
                  modalContent={
          <>
            <Paragraph className={'modal-text'}>{t("mainPage.sectionOneUA.modalOne.textOne")}</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ help }
                            alt={t("mainPage.sectionOneUA.modalOne.altText")}
                            className={ 'logo-sos' } />}
            headline={t("mainPage.sectionOneUA.modalOne.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("mainPage.sectionOneUA.modalTwo.title")}
                  modalButtonName={t("mainPage.sectionOneUA.modalTwo.button")}
                  buttonLink={t("mainPage.sectionOneUA.modalTwo.hrefLink")}
                  modalContent={
          <>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOneUA.modalTwo.textOne")}</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ injection }
                            alt={t("mainPage.sectionOne.modalTwo.altText")}
                            className={ 'injection-icon' }/>}
            headline={t("mainPage.sectionOneUA.modalTwo.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("mainPage.sectionOneUA.modalThree.title")}
                  modalButtonName={t("mainPage.sectionOneUA.modalThree.button")}
                  buttonLink={t("mainPage.sectionOneUA.modalThree.hrefLink")}
                  modalContent={
          <>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOneUA.modalThree.textOne")}</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ pillsPrescription }
                            alt={t("mainPage.sectionOne.modalEight.altText")}
                            className={ 'pills-prescription' } />}
            headline={t("mainPage.sectionOneUA.modalThree.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("mainPage.sectionOneUA.modalFour.title")}
                  modalButtonName={t("mainPage.sectionOneUA.modalFour.button")}
                  buttonLink={t("mainPage.sectionOneUA.modalFour.hrefLink")}
                  modalContent={
          <>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOneUA.modalFour.textOne")}</Paragraph>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOneUA.modalFour.textTwoPartOne")}&nbsp;
              <Link
                title={t("mainPage.sectionOneUA.modalFour.textTwoPartTwoLinkTitle")}
                href={t("mainPage.sectionOneUA.modalFour.textTwoPartTwoLinkHref")}
                target="_blank"
                className={ 'plain-list__link' }
              >{t("mainPage.sectionOneUA.modalFour.textTwoPartTwoLink")}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp;.</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ dataBox }
                            alt={t("mainPage.sectionOne.modalThree.altText")}
                            className={ 'data-box-icon' } />}
            headline={t("mainPage.sectionOneUA.modalFour.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
        <GridTile modalTitle={t("mainPage.sectionOneUA.modalFive.title")}
                  modalButtonName={t("mainPage.sectionOneUA.modalFive.button")}
                  buttonLink={t("mainPage.sectionOneUA.modalFive.hrefLink")}
                  modalContent={
          <>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOneUA.modalFive.textOne")}</Paragraph>
            <Paragraph className={ 'modal-text' }>{t("mainPage.sectionOneUA.modalFive.textTwo")}</Paragraph>
          </>
        }>
          <PreviewTile
            icon={<ReactSVG src={ erbGov }
                            alt={t("mainPage.sectionOne.modalOne.altText")}
                            className={ 'erb-gov-blue' } />}
            headline={t("mainPage.sectionOneUA.modalFive.title")}
            headlineText={t("mainPage.sectionOne.modal.headlineText")}
          >
          </PreviewTile>
        </GridTile>
      </Grid>
    </>
  )
}
